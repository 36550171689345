import { PropsWithChildren } from "react";
import { getResourceImageUrl } from "utils/imageUtils";
import ImageWithFallback from "./atoms/ImageWithFallback";
import { FC } from "react";

type Props = PropsWithChildren & {
  altText: string;
  resourceId?: string;
  width?: number;
  category?: string;
  src?: string;
};
const ContentHeaderImage: FC<Props> = ({
  category,
  altText,
  width,
  resourceId,
  src,
  children,
}) => {
  return (
    <div className="container-2-1">
      <ImageWithFallback
        className="container-2-1-img cover-fit"
        alt={altText}
        src={
          src ??
          getResourceImageUrl(resourceId, {
            width: width,
            category: category,
          })
        }
      />
      {children}
    </div>
  );
};

export default ContentHeaderImage;

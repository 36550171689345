import { FC, PropsWithChildren, useEffect, useState } from "react";
import ImageWithFallback from "./atoms/ImageWithFallback";
import useAppSelector from "../hooks/useAppSelector";
import { getFromHub } from "actions/networkActions";
import { useGetExcursionImages } from "modules/excursions/hooks/useGetExcursionImages";
import { IMxpExcursion } from "interfaces/MXP/IMxpExcursion";
import { getRandomImage } from "utils/imageUtils";

const randomCoverPlaceholderImage = getRandomImage();

type Props = PropsWithChildren & {};

const CruiseHeaderImage: FC<Props> = ({ children }) => {
  const itinerary = useAppSelector((state) => state.itinerary);
  const firstItineraryWithBookableExcursions = itinerary
    // Skip first itinerary as it is not bookable for excursions
    ?.slice(1)
    .find(
      (currentItinerary) => currentItinerary?.numberOfBookableExcursions > 0
    );

  const [firstBookableExcursions, setFirstBookableExcursions] = useState<
    IMxpExcursion[] | null
  >(null);

  useEffect(() => {
    getFromHub(
      `prebooking/cruises/itinerary/${firstItineraryWithBookableExcursions?.id}/excursions`,
      setFirstBookableExcursions
    );
  }, [firstItineraryWithBookableExcursions]);

  const { getExcursionImageThumbnail } = useGetExcursionImages({
    excursionId: firstBookableExcursions?.[0]?.excursion?.id,
  });

  return (
    <div className="container-2-1 md:full-height-container xl:container-2-1">
      <ImageWithFallback
        className="container-2-1-img md:full-height-container-img xl:container-2-1-img cover-fit"
        alt="cruise header"
        src={getExcursionImageThumbnail ?? randomCoverPlaceholderImage}
      />
      {children}
    </div>
  );
};

export default CruiseHeaderImage;

import { getFromHub, postToHub } from "./networkActions";
import { formatTimeHM } from "utils/dateTimeUtils";
import { store } from "WithProviders";
import OpenRefundsConverter from "utils/openRefundsConverter";
import { flattenExcursions } from "utils/cartUtils";
import { Action } from "redux";
import { getTreatmentsBookedCardsList } from "modules/oneSpaWorld/hooks/getTreatmentsBookedCardsList";
import { getCurrentCruisesFromStore } from "./cruisesActions";
import { getMusementBookedCardsList } from "modules/musement/hooks/getMusementBookedCardsList";
import { IChosenGroupToPay } from "interfaces/ReduxState/ICart";
import { getDiningCart } from "modules/mxp/dining/hooks/getDiningCart";

export interface BookingAction extends Action {
  bookings: any;
}

export interface CartAction extends Action {
  cart: any;
}

export interface OpenRefundAction extends Action {
  openRefunds: any;
}

export interface ChosenGroupToPayAction extends Action, IChosenGroupToPay {}

export interface CartActions extends CartAction, ChosenGroupToPayAction {}

export const updateCart = async () => {
  const cartElements = await getFromHub("prebooking/cart/v2");
  const { musementPendingEntriesWithCartUuid, handlingInProgress } =
    await getMusementBookedCardsList();

  const { oswNonPaidBookings } = await getTreatmentsBookedCardsList();

  const { mxpNonPaidBookings } = await getDiningCart();

  const cruiseEntries = cartElements?.entries?.CruiseExcursion
    ? cartElements.entries.CruiseExcursion
    : [];

  const tacEntries = cartElements?.entries?.Tac ? cartElements.entries.Tac : [];

  const cart = parseCartEntries([
    ...cruiseEntries,
    ...tacEntries,
    ...oswNonPaidBookings,
    ...musementPendingEntriesWithCartUuid,
    ...mxpNonPaidBookings,
  ]);

  store.dispatch<CartAction>({
    type: "SET_CART",
    cart: {
      ...cart,
      handlingInProgress,
    },
  });
};

// used
// when user logs in
// when page is reloaded (router mounted)
// when booking is made (payment finished)
// when booking is refunded
export const updateBookings = async () => {
  const bookingEntries = await getFromHub("prebooking/bookings");
  if (bookingEntries.error) {
    return;
  }
  const bookings = parseCartEntries(bookingEntries);

  store.dispatch<BookingAction>({
    type: "SET_BOOKINGS",
    bookings,
  });
};

// used
// when user logs in
// when page is reloaded (router mounted)
// when booking is made (payment finished)
// when booking is refunded
// TODO Fix this -> Bad Code due to rush for OSW feature G.K.
// There is an error on login because cruises is still null and it is trying to read
export const updateOswBookings = async () => {
  const cruises = getCurrentCruisesFromStore();
  if (cruises) {
    const { oswPaidBookings } = await getTreatmentsBookedCardsList();

    const parsedBookings = parseCartEntries(oswPaidBookings);

    store.dispatch<BookingAction>({
      type: "SET_OSW_BOOKINGS",
      bookings: parsedBookings,
    });
  }
};

// used
// when user logs in
// when page is reloaded (router mounted)
// when booking is made (payment finished)
// when booking is refunded
export const updateMusementBookings = async () => {
  const { musementPaidEntriesWithCartUuid } =
    await getMusementBookedCardsList();

  const parsedBookings = parseCartEntries(musementPaidEntriesWithCartUuid);

  store.dispatch<BookingAction>({
    type: "SET_MUSEMENT_BOOKINGS",
    bookings: parsedBookings,
  });
};

export const updateOpenRefunds = async () => {
  const openRefundEntries = await getFromHub(
    "prebooking/bookings/bookingPartGroups"
  );
  const openRefunds = new OpenRefundsConverter(
    openRefundEntries
  ).prepareForStore();

  store.dispatch<OpenRefundAction>({
    type: "SET_OPEN_REFUNDS",
    openRefunds,
  });
};

// used
// when user logs in
// when page is reloaded (router mounted)
// when booking is made (payment finished)
// when booking is refunded
export const updateMxpBookings = async () => {
  const { mxpPaidBookings } = await getDiningCart();

  const parsedBookings = parseCartEntries(mxpPaidBookings);

  store.dispatch<BookingAction>({
    type: "SET_MXP_BOOKINGS",
    bookings: parsedBookings,
  });
};

//--

export const removeExcursionFromCart = async (
  itineraryId: any,
  excursionId: any
) => {
  // get all passengers belonging to the excursion we want to remove
  const bookingId =
    store.getState().cart.excursions[itineraryId][excursionId]
      .bookingInformation;

  const passengers = store
    .getState()
    .cart.excursions[itineraryId][excursionId].passengers.map(
      (passenger: any) => {
        return {
          bookingInformation: bookingId,
          passenger: passenger.id,
          action: "remove",
        };
      }
    );

  // post the changes to the hub
  await postToHub("prebooking/cart", passengers);
};

export const removeNotBookableEntriesFromCart = async () => {
  // get all passengers for all excursions that are not bookable
  //@ts-ignore TS-FIX Me
  const notBookableEntries = store.getState().cart.notBookableEntries;
  let removeCartEntries: any[] = [];

  //create the array with booking informations and passenger ids that need to be removed
  notBookableEntries.excursions.forEach((excursion: any) => {
    excursion.passengers.forEach((passenger: any) => {
      removeCartEntries.push({
        bookingInformation: excursion.bookingInformation,
        passenger: passenger.id,
        action: "remove",
      });
    });
  });

  notBookableEntries.tac.forEach((tacEntry: any) => {
    removeCartEntries.push({
      type: "Tac",
      id: tacEntry.id,
      action: "remove",
    });
  });

  await postToHub("prebooking/cart", removeCartEntries);
};

export const parseCartEntries = (cartElements: any) => {
  let totalPrice = 0;
  let nrItems = 0;
  let excursions = {};
  let tacEntries = {};
  let oswEntries = {};
  let musementEntries = {};
  let mxpEntries = {};
  let notBookableEntries = { excursions: {}, tac: [] };

  cartElements?.forEach((cartElement: any) => {
    if (cartElement.type === "CruiseExcursion") {
      ({
        excursions,
        nrExcursions: nrItems,
        totalPrice,
        notBookableEntries,
      } = addExcursion(
        cartElement,
        excursions,
        nrItems,
        totalPrice,
        notBookableEntries
      ));
    }

    if (cartElement.type === "BookingPartTac" || cartElement.type === "Tac") {
      ({
        tacEntries,
        nrExcursions: nrItems,
        totalPrice,
        notBookableEntries,
      } = addTacEntry(
        cartElement,
        tacEntries,
        nrItems,
        totalPrice,
        notBookableEntries
      ));
    }

    // Only excursions are comming as array
    if (cartElement instanceof Array) {
      cartElement?.forEach((cartElements: any[]) => {
        // Do not map empty arrays as it should not be counter as activity(excursion)
        if (cartElements?.length > 0)
          ({
            musementEntries,
            nrExcursions: nrItems,
            totalPrice,
          } = addMusementEntry({
            cartElements,
            musementEntries,
            nrItems,
            totalPrice,
          }));
      });
    }

    if (cartElement?.VenueId) {
      ({
        mxpEntries,
        nrEntries: nrItems,
        totalPrice,
      } = addMxpEntry(cartElement, mxpEntries, nrItems, totalPrice));
    }

    if (cartElement.treatmentId) {
      ({
        oswEntries,
        nrExcursions: nrItems,
        totalPrice,
      } = addOswEntry(cartElement, oswEntries, nrItems, totalPrice));
    }
  });

  //flatten the notBookableEntries.excursions to an array
  notBookableEntries.excursions = flattenExcursions(
    notBookableEntries.excursions
  );

  return {
    totalPrice,
    nrExcursions: nrItems,
    excursions,
    notBookableEntries,
    tacEntries,
    oswEntries,
    musementEntries,
    mxpEntries,
  };
};

const addExcursion = (
  cartElement: any,
  excursions: any,
  nrExcursions: number,
  totalPrice: number,
  notBookableEntries: any
) => {
  // only take the data we will need/use
  const itineraryId = cartElement.itinerary.id;
  const excursionId = cartElement.excursion.id;
  const passengerData = {
    ...cartElement.passenger,
    cartElementId: cartElement.id,
    price: cartElement.price,
    sessionType: cartElement.sessionType,
    status: cartElement.status,
  };

  const excursionData = {
    excursionId,
    itineraryId,
    excursionName: cartElement.excursion.name.defaultValue,
    portName: cartElement.excursion.port.name.defaultValue,
    portCountry: cartElement.excursion.port.countryName,
    price: cartElement.price,
    passengers: [passengerData],
    bookingInformation: cartElement.bookingInformation,
    date: cartElement.itinerary.date,
    duration: cartElement.excursion.duration,
  };

  // update price of all entries in cart
  totalPrice += cartElement.price;

  // add the current cart element to the excursions object
  if (!excursions.hasOwnProperty(itineraryId)) {
    // itinerary entry does not yet exist, create it... and add add excursion data
    nrExcursions++;
    excursions[itineraryId] = {};
    excursions[itineraryId][excursionId] = excursionData;
  } else {
    if (excursions[itineraryId].hasOwnProperty(excursionId)) {
      // excursion already exists, add the passenger and update the price of the excursion
      excursions[itineraryId][excursionId].price += cartElement.price;
      excursions[itineraryId][excursionId].passengers.push(passengerData);
    } else {
      // excursion does not exist yet, add it
      nrExcursions++;
      excursions[itineraryId][excursionId] = excursionData;
    }
  }

  //molecules/If the status is not bookable, add the element to the notBookableEntries
  if (cartElement.status !== "Bookable") {
    const notBookableExcursionData = {
      excursionId,
      excursionName: cartElement.excursion.name.defaultValue,
      portName: cartElement.excursion.port.name.defaultValue,
      portCountry: cartElement.excursion.port.countryName,
      price: cartElement.price,
      passengers: [passengerData],
      bookingInformation: cartElement.bookingInformation,
    };

    if (!notBookableEntries.excursions.hasOwnProperty(itineraryId)) {
      // itinerary entry does not yet exist, create it... and add add excursion data
      notBookableEntries.excursions[itineraryId] = {};
      notBookableEntries.excursions[itineraryId][excursionId] =
        notBookableExcursionData;
    } else {
      if (
        notBookableEntries.excursions[itineraryId].hasOwnProperty(excursionId)
      ) {
        // excursion already exists, add the passenger and update the price of the excursion
        notBookableEntries.excursions[itineraryId][excursionId].price +=
          cartElement.price;
        notBookableEntries.excursions[itineraryId][excursionId].passengers.push(
          passengerData
        );
      } else {
        // excursion does not exist yet, add it
        notBookableEntries.excursions[itineraryId][excursionId] =
          notBookableExcursionData;
      }
    }
  }

  return {
    excursions,
    nrExcursions,
    totalPrice,
    notBookableEntries,
  };
};

const addTacEntry = (
  cartElement: any,
  tacEntries: any,
  nrExcursions: number,
  totalPrice: number,
  notBookableEntries: any
) => {
  const date = cartElement.fromDate
    ? cartElement.fromDate
    : cartElement.fromTime.substring(0, 10);

  const time = cartElement.fromDate
    ? cartElement.fromTime
    : formatTimeHM(cartElement.fromTime, "Couldn't get time");

  if (!tacEntries.hasOwnProperty(date)) {
    tacEntries[date] = [];
  }

  const specialRequirements =
    cartElement.specialRequirements && cartElement.specialRequirements[0]
      ? cartElement.specialRequirements
      : [];
  const tacEntryData = {
    id: cartElement.id,
    serviceProfileId: cartElement.serviceProfile.id,
    name: cartElement.serviceProfile.name.defaultValue,
    time,
    nrAdults: cartElement.numberOfAdults,
    nrChildren: cartElement.numberOfChildren,
    price: cartElement.price,
    sessionType: cartElement.sessionType || "",
    specialRequirements,
  };
  //add the tac reservation info
  tacEntries[date].push(tacEntryData);

  // increment the total price
  totalPrice += cartElement.price;
  nrExcursions++;

  //molecules/If the status is not bookable, add the element to the notBookableEntries
  if (cartElement.status !== "Bookable") {
    notBookableEntries.tac.push(tacEntryData);
  }

  return {
    tacEntries,
    nrExcursions,
    totalPrice,
    notBookableEntries,
  };
};

const addOswEntry = (
  cartElement: any,
  oswEntries: any,
  nrExcursions: number,
  totalPrice: number
) => {
  const date = cartElement.scheduledDay;

  if (!oswEntries.hasOwnProperty(date)) {
    oswEntries[date] = [];
  }

  oswEntries[date].push(cartElement);

  // increment the total price
  totalPrice += cartElement.price;
  nrExcursions++;

  return {
    oswEntries,
    nrExcursions,
    totalPrice,
  };
};

const addMxpEntry = (
  cartElement: any,
  mxpEntries: any,
  nrEntries: number,
  totalPrice: number
) => {
  const date = cartElement.Date;

  if (!mxpEntries.hasOwnProperty(date)) {
    mxpEntries[date] = [];
  }

  mxpEntries[date].push(cartElement);
  nrEntries++;
  totalPrice += cartElement.totalAmount;
  return {
    mxpEntries,
    nrEntries,
    totalPrice,
  };
};

type MusementEntryProps = {
  cartElements: any[];
  musementEntries: any;
  nrItems: number;
  totalPrice: number;
};

const addMusementEntry = ({
  cartElements,
  musementEntries,
  nrItems,
  totalPrice,
}: MusementEntryProps) => {
  const date = cartElements?.[0]?.product?.scheduledDay;

  if (!musementEntries.hasOwnProperty(date)) {
    musementEntries[date] = [];
  }

  musementEntries[date].push(cartElements);

  // increment the total price
  cartElements?.forEach((cartElement: any) => {
    totalPrice += cartElement.totalPrice;
  });
  nrItems++;

  return {
    musementEntries,
    nrExcursions: nrItems,
    totalPrice,
  };
};

export const refundBooking = async (bookingIds: any, paymentReference: any) => {
  const clientDomainUrl =
    window.location.protocol + "//" + window.location.host;

  const refundData = {
    paymentReference,
    ids: bookingIds,
    clientDomainUrl,
  };

  const response = await postToHub("prebooking/bookings/cancel", refundData);
  return response;
};

export const getCurrentCartFromStore = () => {
  return store.getState().cart;
};

export const orderCart = (
  payload: any,
  successCallback: (data: any) => void,
  errorCallback: (e: any) => void,
  useV2 = false
) => {
  const url = useV2 ? "prebooking/cart/v2/order" : "prebooking/cart/order";
  postToHub(url, payload, successCallback, errorCallback);
};

export const setChosenGroupToPay = async ({
  chosenGroupToPay,
}: IChosenGroupToPay) => {
  store.dispatch<ChosenGroupToPayAction>({
    type: "SET_CHOSEN_GROUP_TO_PAY",
    chosenGroupToPay,
  });
};

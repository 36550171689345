import moment from "moment/moment";
import { getCurrentCruisesFromStore } from "../../actions/cruisesActions";
import { ICruise } from "interfaces/ReduxState/ICruise";

export const flattenExcursions = (
  cartElements: any,
  cruiseStartDateString: string | null = null
) => {
  let excursions: any[] = [];

  if (!cartElements) {
    return excursions;
  }

  const startDate = cruiseStartDateString
    ? moment(cruiseStartDateString, "DD/MM/YYYY")
    : null;

  for (const itineraryId in cartElements) {
    for (const excursionId in cartElements[itineraryId]) {
      const excursionInfo = { ...cartElements[itineraryId][excursionId] };

      if (startDate) {
        excursionInfo.dayNr =
          moment(
            cartElements[itineraryId][excursionId].date,
            "DD/MM/YYYY"
          ).diff(startDate, "days") + 1;
      }

      excursions.push(excursionInfo);
    }
  }

  return excursions;
};

// this function was created to flatten TAC entries.
// but after OSW entries appeared - this also works fine for them
export const flattenEntries = (
  entries: any,
  cruiseStartDateString: any = null
) => {
  if (!entries) {
    return [];
  }

  if (cruiseStartDateString === null) {
    const currentCruises: any = getCurrentCruisesFromStore();
    if (!currentCruises) {
      return [];
    }

    cruiseStartDateString = currentCruises.cruiseStartDate;
  }
  if (!cruiseStartDateString) {
    return [];
  }

  const startDate = moment(cruiseStartDateString, "DD/MM/YYYY");

  // push all the entries to single array and attach the date and day information
  let entriesToPopulate = [];
  for (const date in entries) {
    if (entries.hasOwnProperty(date)) {
      const dayNr = moment(date, "DD/MM/YYYY").diff(startDate, "days") + 1;
      const entriesForDate = entries[date].map((entry: any) => {
        entry.dayNr = dayNr;
        entry.date = date;
        return entry;
      });

      entriesToPopulate.push(...entriesForDate);
    }
  }

  return entriesToPopulate;
};

// this function was created to flatten TAC entries.
// but after OSW entries appeared - this also works fine for them
export const flattenMxpEntries = (
  entries: any,
  cruiseStartDateString: any = null
) => {
  if (!entries) {
    return [];
  }

  if (cruiseStartDateString === null) {
    const currentCruises: any = getCurrentCruisesFromStore();
    if (!currentCruises) {
      return [];
    }

    cruiseStartDateString = currentCruises.cruiseStartDate;
  }
  if (!cruiseStartDateString) {
    return [];
  }

  const startDate = moment(cruiseStartDateString, "DD/MM/YYYY");

  // push all the entries to single array and attach the date and day information
  let entriesToPopulate = [];
  for (const date in entries) {
    if (entries.hasOwnProperty(date)) {
      const dayNr = moment(date, "YYYY-MM-DD").diff(startDate, "days") + 1;
      const entriesForDate = entries[date].map((entry: any) => {
        entry.dayNr = dayNr;
        entry.date = date;
        return entry;
      });

      entriesToPopulate.push(...entriesForDate);
    }
  }

  return entriesToPopulate;
};

export const flattenMusementEntries = (
  entries: any,
  cruiseStartDateString: any = null
) => {
  if (!entries) {
    return [];
  }

  if (cruiseStartDateString === null) {
    const currentCruises: any = getCurrentCruisesFromStore();
    if (!currentCruises) {
      return [];
    }

    cruiseStartDateString = currentCruises.cruiseStartDate;
  }
  if (!cruiseStartDateString) {
    return [];
  }

  const startDate = moment(cruiseStartDateString, "DD/MM/YYYY");

  // push all the entries to single array and attach the date and day information
  let entriesToPopulate = [];
  for (const date in entries) {
    if (entries.hasOwnProperty(date)) {
      const dayNr = moment(date, "DD/MM/YYYY").diff(startDate, "days") + 1;
      const entriesForDate = entries[date].map((entry: any) => {
        entry?.forEach((cartItem: any) => {
          cartItem.dayNr = dayNr;
          cartItem.date = date;
        });
        return entry;
      });

      entriesToPopulate.push(...entriesForDate);
    }
  }

  return entriesToPopulate;
};

export const priceWithTwoDecimals = (price: string) => {
  const formattedPrice = parseFloat(price).toFixed(2);

  // sometimes string value "XX" has been passed to this function and getting "NaN"
  if (formattedPrice === "NaN") {
    return "N/A";
  }

  return parseFloat(price).toFixed(2);
};

export const mergeAndSortEntries = (
  excursions: any,
  tacEntries: any,
  oswEntries: any,
  musementEntries: any,
  mxpEntries: any,
  cruiseStartDate: ICruise["cruiseStartDate"]
) => {
  const combinedEntries = [
    ...flattenExcursions(excursions, cruiseStartDate),
    ...flattenEntries(tacEntries, cruiseStartDate),
    ...flattenEntries(oswEntries, cruiseStartDate),
    ...flattenMusementEntries(musementEntries, cruiseStartDate),
    ...flattenMxpEntries(mxpEntries, cruiseStartDate),
  ];

  combinedEntries.sort((a, b) => {
    if (a.dayNr === b.dayNr) {
      // 2 excursions or 2 restaurants return 0
      if (
        (a.excursionId && b.excursionId) ||
        (!a.excursionId && !b.excursionId)
      ) {
        return 0;
      }

      // excursions go before restaurants on same day
      return a.excursionId ? -1 : 1;
    }

    // "smaller" days go first
    return a.dayNr > b.dayNr ? 1 : -1;
  });

  return combinedEntries;
};

export const getDayNr = (
  date: string | any,
  cruiseStartDateString: ICruise["cruiseStartDate"] = null
) => {
  if (!cruiseStartDateString) {
    return 0;
  }
  const startDate = moment(cruiseStartDateString, "DD/MM/YYYY");

  return moment(date, "DD/MM/YYYY").diff(startDate, "days") + 1;
};

import BackLink from "components/molecules/BackLink";

const PaymentFailureDiningUnavailability = () => {
  return (
    <div>
      <div className="container mx-auto mt-4 text-primary-dark">
        <div className="mb-4">
          <BackLink to="/checkout" text="Checkout" />
        </div>
        <div className="text-xl">
          Unfortunately, we don’t have any tables available for your dining
          party to book at this time. Please enquire about your dining options
          onboard
        </div>
      </div>
    </div>
  );
};

export default PaymentFailureDiningUnavailability;

import Price from "components/Price/Price";
import { IMxpMeal } from "interfaces/MXP/IMxpMeal";

type Props = {
  meal: IMxpMeal;
  onClick: () => void;
};

const MxpMealTile = ({ meal, onClick }: Props) => {
  const { mealType, costPerPerson } = meal;

  return (
    <button onClick={onClick}>
      <div className="flex flex-col bg-white border text-center p-4 my-2 mr-2 shadow">
        <div className="text-lg font-bold text-blue-dark">{mealType}</div>
        <div className="mt-2">
          <div className="flex items-center justify-center">
            <Price price={String(costPerPerson)} postText=" pp" />
          </div>
        </div>
      </div>
    </button>
  );
};

export default MxpMealTile;

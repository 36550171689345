import axios from "axios";
import { getOswUrl, getShipCodeByName } from "config/configUtils";
import useAppSelector from "hooks/useAppSelector";
import { useCallback, useState } from "react";
import { getCruisesInfo } from "utils/cruiseUtils";

enum TreatmentsStatusesEnum {
  pending = "createAndPutOnHold",
  paid = "commit",
}

export const useGetTreatmentsBookedCardsList = () => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);
  const cruiseDetails = useAppSelector(
    (state) => state?.cruises?.cruiseDetails
  );

  const [oswPaidBookings, setOswPaidBookings] = useState([]);
  const [oswNonPaidBookings, setOswNonPaidBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const getTreatmentsBookedCardsList = useCallback(async () => {
    if (!cruiseDetails) {
      return {
        oswPaidBookings: [],
        oswNonPaidBookings: [],
        error: null,
        loading: false,
      };
    }

    const cruisesInfo = getCruisesInfo(cruiseDetails);
    setLoading(true);
    const URL = `${getOswUrl()}/api/ships/${getShipCodeByName({
      shipName: cruisesInfo?.shipName,
    })}/planner/booked-card`;

    const response = await axios
      .get(URL, {
        headers: {
          SessionId: sessionId,
        },
      })
      .catch((error) => {
        console.error("getTreatmentsBookedCardsList", error);
        setError(error);
      });

    const oswPaidBookings =
      response?.data.bookedSpaTreatmentDetailsDtos?.filter(
        (oswEntry: any) => oswEntry?.status === TreatmentsStatusesEnum.paid
      ) ?? [];

    const oswNonPaidBookings =
      response?.data.bookedSpaTreatmentDetailsDtos?.filter(
        (oswEntry: any) => oswEntry?.status === TreatmentsStatusesEnum.pending
      ) ?? [];

    setOswPaidBookings(oswPaidBookings);
    setOswNonPaidBookings(oswNonPaidBookings);
    setLoading(false);

    return {
      oswPaidBookings,
      oswNonPaidBookings,
      error,
      loading,
    };
  }, [cruiseDetails, error, loading, sessionId]);

  return {
    getTreatmentsBookedCardsList,
    oswPaidBookings,
    oswNonPaidBookings,
    error,
    loading,
  };
};

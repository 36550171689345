import axios from "axios";
import { IMxpAvailabilitySlot } from "interfaces/MXP/IMxpAvailabilitySlot";
import { useQuery } from "@tanstack/react-query";
import useAppSelector from "hooks/useAppSelector";
import { getDinningUrl } from "config/configUtils";
import { useCheckFeatureFlags } from "hooks/common/featureFlags/useCheckFeatureFlags";

type Props = {
  venueId: number;
  mealId?: number;
  date: string;
  numberOfPeople: number;
};

export const useGetRestaurantsSlots = ({
  venueId,
  mealId,
  date,
  numberOfPeople,
}: Props) => {
  const { isNewDiningGetAvailabilitiesEnabled } = useCheckFeatureFlags();
  const sessionId = useAppSelector((state) => state.authentication.sessionId);

  const nonFeatureFlagURL = `${getDinningUrl()}/api/dining/availability?venueId=${venueId}&date=${date}&numberOfPersons=${numberOfPeople}`;
  const featureFlagURL = `${getDinningUrl()}/api/dining/availability-extended?venueId=${venueId}&date=${date}&numberOfPersons=${numberOfPeople}&mealId=${mealId}`;

  const URL = isNewDiningGetAvailabilitiesEnabled
    ? featureFlagURL
    : nonFeatureFlagURL;

  const { refetch, status, data, error, isFetching, isSuccess } = useQuery({
    queryKey: ["getRestaurantsSlots", venueId, mealId, date, numberOfPeople],
    queryFn: async () =>
      await axios
        .get(URL, {
          headers: {
            SessionId: sessionId,
          },
        })
        .then((res) => res.data as Promise<IMxpAvailabilitySlot[]>),
    refetchOnWindowFocus: false,
    enabled:
      !!venueId &&
      !!date &&
      !!numberOfPeople &&
      (isNewDiningGetAvailabilitiesEnabled ? !!mealId : true),
  });

  return {
    getRestaurantsSlots: refetch,
    data: data || [],
    status,
    error,
    isFetching,
    isSuccess,
  };
};

import { getFromHub } from "./networkActions";
import { store } from "WithProviders";
import { getMusementBookedCardsList } from "modules/musement/hooks/getMusementBookedCardsList";
import { useCallback, useState } from "react";
import { CartAction, parseCartEntries } from "./cartActions";
import { useGetTreatmentsBookedCardsList } from "modules/oneSpaWorld/hooks/useGetTreatmentsBookedCardsList";
import { useGetDiningCart } from "modules/mxp/dining/hooks/useGetDiningCart";

// TODO Check MM,OSW,MXP excursions and TAC dining after change of this function
export const useUpdateCart = () => {
  const [loading, setLoading] = useState(false);

  const { getTreatmentsBookedCardsList } = useGetTreatmentsBookedCardsList();
  const { getDiningCart } = useGetDiningCart();

  const updateCart = useCallback(async () => {
    setLoading(true);
    try {
      const cartElements = await getFromHub("prebooking/cart/v2");
      const { data } = await getDiningCart();
      const { musementPendingEntriesWithCartUuid, handlingInProgress } =
        await getMusementBookedCardsList();

      const { oswNonPaidBookings } = await getTreatmentsBookedCardsList();

      const cruiseEntries = cartElements?.entries?.CruiseExcursion || [];
      const tacEntries = cartElements?.entries?.Tac || [];
      const mxpNonPaidBookings =
        data?.filter(
          (mxpDiningBooking) => mxpDiningBooking?.status === "PREBOOK_OK"
        ) ?? [];

      const cart = parseCartEntries([
        ...cruiseEntries,
        ...tacEntries,
        ...oswNonPaidBookings,
        ...musementPendingEntriesWithCartUuid,
        ...mxpNonPaidBookings,
      ]);

      store.dispatch<CartAction>({
        type: "SET_CART",
        cart: {
          ...cart,
          handlingInProgress,
        },
      });
    } catch (error) {
      console.error("Failed to update cart:", error);
    } finally {
      setLoading(false);
    }
  }, [getDiningCart, getTreatmentsBookedCardsList]);

  return { updateCart, loading };
};

import axios from "axios";
import { getPrebookUrl } from "config/configUtils";
import { store } from "WithProviders";

enum MusementProductStatusesEnum {
  pending = "PREBOOK_OK",
  paid = "OK",
  canceled = "CANCELED",
  refunded = "REFUNDED",
}

enum MusementCartsEnum {
  pending = "CREATED",
  paid = "PAID",
  partial_refund = "PARTIAL_REFUNDED",
  canceled = "CANCELED",
}

export const getMusementBookedCardsList = async () => {
  const sessionId = store.getState().authentication.sessionId;
  const URL = `${getPrebookUrl()}/api/carts`;

  const response = await axios
    .get(URL, {
      headers: {
        SessionId: sessionId,
      },
    })
    .catch((error) => {
      console.error("getMusementBookedCardsList", error);
    });

  const cartItems = response?.data.handlingInProgress
    ? []
    : response?.data.cartResponseDtos;

  const paidCarts = cartItems?.filter(
    (cart: any) => cart?.status === MusementCartsEnum.paid
  );

  const partialRefundCarts = cartItems?.filter(
    (cart: any) => cart?.status === MusementCartsEnum.partial_refund
  );

  const pendingCarts = cartItems?.filter(
    (cart: any) => cart?.status === MusementCartsEnum.pending
  );

  const handlingInProgress = response?.data.handlingInProgress || false;

  const musementPaidEntries =
    paidCarts?.map((cart: any) =>
      cart?.transformedItems?.map((items: any) =>
        items?.filter(
          (item: any) => item?.status === MusementProductStatusesEnum.paid
        )
      )
    ) ?? [];

  const musementPaidFromPartialRefundEntries =
    partialRefundCarts?.map((cart: any) =>
      cart?.transformedItems?.map((items: any) =>
        items?.filter((item: any) => {
          return item?.status === MusementProductStatusesEnum.paid;
        })
      )
    ) ?? [];

  const musementPendingEntries =
    pendingCarts?.map((cart: any) =>
      cart?.transformedItems?.map((items: any) =>
        items?.filter(
          (item: any) => item?.status === MusementProductStatusesEnum.pending
        )
      )
    ) ?? [];

  return {
    musementPaidEntriesWithCartUuid: [
      ...musementPaidFromPartialRefundEntries,
      ...musementPaidEntries,
    ],
    musementPendingEntriesWithCartUuid: musementPendingEntries,
    handlingInProgress,
  };
};

import { Link } from "react-router-dom";
import { getResourceImageUrl } from "utils/imageUtils";
import CenteredImageContainer from "../CenteredImageContainer";
import SvgIcon from "../atoms/SvgIcon";
import { LocationDescriptor } from "history";
import { FC, ReactNode } from "react";
import { buildTestId } from "utils/buildTestId";
import ActionButton from "components/Buttons/ActionButton";

import browserHistory from "utils/history";
import { imagePath } from "utils/imageUtils";

type Props = {
  name: string;
  imageReference: any;
  imageCategory?: string;
  remove: () => void;
  linkTo?: string | LocationDescriptor;
  changePassengersLinkText: string;
  changePassengersLinkTo: string | LocationDescriptor;
  children: ReactNode;
  dataTest: string;
  isOldSolutionForImage?: boolean;
};
const CartElementItinerary: FC<Props> = ({
  name,
  imageReference,
  imageCategory,
  remove,
  linkTo,
  changePassengersLinkText,
  changePassengersLinkTo,
  children,
  dataTest,
  isOldSolutionForImage = true,
}) => {
  return (
    <div
      className="flex flex-wrap bg-white shadow sm:p-4 my-4"
      data-testid={buildTestId("container", `cartElementItinerary ${dataTest}`)}
    >
      {/* image */}
      <div className="w-full sm:w-1/2 md:w-2/5 xl:w-1/3">
        <CenteredImageContainer
          alt={name}
          src={
            isOldSolutionForImage
              ? getResourceImageUrl(imageReference, {
                  width: 400,
                  category: imageCategory,
                })
              : imageReference
          }
        />
      </div>

      {/* rest of card */}
      <div className="xl:flex xl:flex-col px-4 py-2 sm:p-0 w-full sm:w-1/2 md:w-3/5 xl:w-2/3 sm:pl-4 mt-2 sm:mt-0 relative">
        {/* Remove from cart */}
        <span
          className="text-blue cursor-pointer absolute top-0 right-0 mr-4 mt-2 sm:m-0"
          onClick={remove}
        >
          <span className="hidden md:inline">Remove</span>{" "}
          <SvgIcon name="x" className="w-3 h-3 inline ml-1" />
        </span>

        {/* title link*/}
        <div className="mr-6 md:mr-16">
          {linkTo ? (
            <Link
              className="text-blue underline text-2xl uppercase sm:pr-3 md:pr-0"
              to={linkTo}
            >
              {name}
            </Link>
          ) : (
            <div className="text-blue underline text-2xl uppercase sm:pr-3 md:pr-0">
              {name}
            </div>
          )}
        </div>

        {/*details, change link and price info*/}
        <div className="xl:flex xl:justify-between xl:flex-grow">
          <div className="xl:flex xl:flex-col xl:justify-between">
            <div>{children}</div>
            <Link
              className="text-blue underline cursor-pointer"
              to={changePassengersLinkTo}
            >
              {changePassengersLinkText}
            </Link>
          </div>

          <div className="flex flex-col items-end justify-end text-right w-full xl:w-auto mt-4 xl:mt-0">
            <ActionButton
              onClick={() => {
                browserHistory.push("/checkout");
              }}
              disabled={false}
              classes="w-full mt-3 sm:px-6 sm:w-auto"
              buttonText="Continue To Payment"
            />
            <div className="flex items-center mt-2 text-left">
              <img
                alt=""
                className="w-4 h-4 mr-2 inline"
                src={imagePath("infoicon_inverted.svg")}
              />
              <p className="m-0">
                Bookings are not confirmed until payment is made.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartElementItinerary;

import axios from "axios";

import useAppSelector from "hooks/useAppSelector";
import { useQuery } from "@tanstack/react-query";
import { getDinningUrl } from "config/configUtils";
import { IMxpRestaurantResponseItem } from "interfaces/MXP/IMxpRestaurant";

type Props = {
  fromDate?: string | null;
  toDate?: string | null;
};

// Example how we can make GET requests
export const useGetRestaurants = ({ fromDate, toDate }: Props) => {
  const sessionId = useAppSelector((state) => state.authentication.sessionId);
  const URL = `${getDinningUrl()}/api/dining?fromDate=${fromDate}&toDate=${toDate}`;

  const { refetch, status, data, error, isFetching, isSuccess } = useQuery({
    queryKey: ["getRestaurants"],
    queryFn: async () =>
      await axios
        .get(URL, {
          headers: {
            SessionId: sessionId,
          },
        })
        .then((res) => res.data as Promise<IMxpRestaurantResponseItem[]>),
    refetchOnWindowFocus: false,
    enabled: !!fromDate && !!toDate,
  });

  return {
    getRestaurants: refetch,
    data: data ?? [],
    status,
    error,
    isFetching,
    isSuccess,
  };
};

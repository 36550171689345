import { Link } from "react-router-dom";
import If from "../../components/molecules/IF";
import SoldOutBox from "../../components/molecules/SoldOutBox";
import PriceInfoBox from "../../components/PriceInfoBox/PriceInfoBox";
import ActivityDuration from "../../components/molecules/ActivityDuration";
import ExcursionAttributes from "../../components/ExcursionAttributes";
import { getResourceImageUrl } from "utils/imageUtils";
import CenteredImageContainer from "../../components/CenteredImageContainer";
import { FC } from "react";
import { useGetExcursionImages } from "./hooks/useGetExcursionImages";

type Props = {
  first: boolean;
  itinerary: any;
  id: any;
  currentAvailability: number;
  name: any;
  excursionTypes: any;
  duration: any;
  price: any;
  minimumAge: any;
};

const ExcursionEntry: FC<Props> = ({
  first,
  itinerary,
  id,
  currentAvailability,
  name,
  excursionTypes,
  duration,
  price,
  minimumAge,
}) => {
  const containerClasses =
    "bg-white border border-blue flex flex-wrap" + (first ? "" : " mt-8");
  const excursionLink = `/excursion/${itinerary}/${id}`;
  const priceBoxDivClasses = `${
    currentAvailability > 0 ? "w-1/2" : "w-full"
  } sm:w-1/3 xl:w-1/4 self-end py-4 px-2 sm:px-4`;

  const { getExcursionImageThumbnail } = useGetExcursionImages({
    excursionId: id,
  });

  return (
    <div className={containerClasses}>
      <div className="w-full sm:w-1/3 p-2 sm:p-4">
        <CenteredImageContainer
          alt="excursion preview"
          src={
            getExcursionImageThumbnail ??
            getResourceImageUrl(id, {
              width: 400,
              category: "cruiseExcursion",
            })
          }
        />
      </div>

      <div className="w-1/2 sm:w-1/3 xl:flex-grow px-2 sm:px-0 pb-4 sm:pt-4 flex flex-col">
        <Link
          className="text-blue underline text-2xl uppercase"
          to={excursionLink}
        >
          {name.defaultValue}
        </Link>

        <ExcursionAttributes attributes={excursionTypes} />

        <ActivityDuration duration={duration} />
      </div>

      <div className={priceBoxDivClasses}>
        <If test={currentAvailability <= 0}>
          <SoldOutBox />
          <Link
            className="block w-full action-button action-button-enabled mt-4"
            to={excursionLink}
          >
            More details
          </Link>
        </If>

        <If test={currentAvailability > 0}>
          <PriceInfoBox
            classes="w-full text-blue-dark float-right"
            adultPrice={price.adult}
            childPrice={price.child}
            minimumAge={minimumAge}
            buttonText="More details"
            buttonIsLink={true}
            linkTo={excursionLink}
            currentAvailability={currentAvailability}
          />
        </If>
      </div>
    </div>
  );
};

export default ExcursionEntry;

import { FC, useEffect, PropsWithChildren, useCallback, useState } from "react";
import CheckoutSubheader from "./CheckoutSubheader";
import CruisesOverview from "./CruisesOverview";
import Price from "./Price/Price";
import { imagePath } from "../utils/imageUtils";
import {
  updateBookings,
  updateOswBookings,
  updateMusementBookings,
  updateMxpBookings,
} from "../actions/cartActions";
import { logOutPassenger } from "../actions/authenticationActions";
import CartElementsConfirmationCombined from "./cartElements/CartElementsConfirmationCombined";
import BackLink from "./molecules/BackLink";
import useAppSelector from "../hooks/useAppSelector";
import { clearPayment } from "actions/paymentActions";
import { useDispatch } from "react-redux";
import { useUpdateCart } from "actions/useUpdateCart";
import { ICartState } from "interfaces/ReduxState/ICart";

type Props = {
  bookingNr: any;
  includePrintLink?: boolean;
  isB2B?: boolean;
  location?: any;
};

const PaymentConfirmationOverview: FC<Props> = ({
  bookingNr,
  includePrintLink,
  isB2B,
  location,
}) => {
  const locationState = location?.state;
  const isAgency = useAppSelector((state) => state.agency.isAuthenticated);
  const payment = useAppSelector((state) => state.payment);
  const stateCart = useAppSelector((state) => state.cart);
  const cruisesSnapshot = useAppSelector((state) => state.cruises);
  const chosenGroupToPay = useAppSelector(
    (state) => state.cart.chosenGroupToPay
  );

  const [cartSnapshot, setCartSnapshot] = useState<ICartState>({
    totalPrice: 0,
    nrExcursions: 0,
    chosenGroupToPay: "ExcursionAndDinning",
    excursions: null,
    notBookableEntries: { excursions: [], tac: [] },
    tac: {},
    osw: {},
    musement: {},
    mxp: {},
    handlingInProgress: false,
  });

  const { updateCart } = useUpdateCart();

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.logoutUserOnMount) {
      logOutPassenger();
    }
  }, [location?.logoutUserOnMount]);

  useEffect(() => {
    setCartSnapshot(stateCart);

    dispatch(clearPayment());
    updateCart();
    updateBookings();
    updateOswBookings();
    updateMusementBookings();
    updateMxpBookings();
  }, []);

  // G.k. TODO get the total price from BE and remove the function from PasengerDetails also
  const getCartSumBySelectedCart = useCallback(() => {
    let oswPrice = 0;
    let musementPrice = 0;
    let mxpPrice = 0;

    for (let [, v] of Object.entries(cartSnapshot.osw)) {
      // @ts-ignore
      oswPrice += v?.reduce((sum, currentValue) => {
        return sum + currentValue?.price;
      }, 0);
    }

    for (let [, musementEntriesArray] of Object?.entries(
      cartSnapshot.musement
    )) {
      let entrySum = 0;
      musementEntriesArray?.forEach((entryArrays) => {
        entryArrays?.forEach((entry) => {
          entrySum += entry?.totalPrice;
        });
      });
      musementPrice += entrySum;
    }

    for (let [, v] of Object.entries(cartSnapshot.mxp)) {
      mxpPrice += v?.reduce((sum, currentValue) => {
        return sum + currentValue?.totalAmount;
      }, 0);
    }

    if (cartSnapshot.osw && chosenGroupToPay === "OSW") {
      return oswPrice;
    } else if (cartSnapshot.osw && chosenGroupToPay === "MusementExcursions") {
      return musementPrice;
    } else if (cartSnapshot.mxp && chosenGroupToPay === "MxpDining") {
      return mxpPrice;
    } else {
      // Extra check to make total price - if it is undefined
      // Better subtract 0 instead of NaN
      oswPrice = isNaN(oswPrice) ? 0 : oswPrice;
      oswPrice = isNaN(musementPrice) ? 0 : oswPrice;
      oswPrice = isNaN(mxpPrice) ? 0 : oswPrice;
      return cartSnapshot.totalPrice - oswPrice - musementPrice;
    }
  }, [
    cartSnapshot.musement,
    cartSnapshot.mxp,
    cartSnapshot.osw,
    cartSnapshot.totalPrice,
    chosenGroupToPay,
  ]);

  return (
    <div>
      <CheckoutSubheader active="confirmation" hideOnMobile={1} />

      <div className="container px-4 mx-auto mt-4 text-blue-dark">
        <div className="mb-4">
          <div className="print-hidden">
            <BackLink
              to={isAgency ? "/login" : "/itinerary"}
              text={
                isAgency
                  ? "Return to passenger login"
                  : "Return to Cruise Planner"
              }
            />
          </div>
        </div>
        <div className="text-4xl xl:inline xl:pr-2">All booked!</div>
        <div className="text-3xl mt-3 xl:mt-0 xl:inline">
          Thanks for choosing Marella for your cruise experiences.
        </div>
      </div>

      <div className="container px-4 mx-auto">
        {/*booking reference box*/}
        <div className="bg-blue-lightest-25 p-4 mt-6 shadow">
          <div className="text-blue-dark uppercase pr-2">
            Booking Reference number:{" "}
            <span className="font-bold">{bookingNr}</span>
          </div>
        </div>

        {includePrintLink && (
          <div className="mt-4">
            <button
              className="text-blue cursor-pointer print-hidden"
              onClick={() => {
                window.print();
              }}
            >
              Print confirmation
            </button>
          </div>
        )}

        {/* excursions information */}
        <div className="mt-8 px-2 sm:px-4">
          <div className="border-b print-border-0 pb-2 sm:flex sm:justify-between sm:items-center ">
            <h2>Your cruise experiences</h2>
          </div>

          <div className="text-blue-dark">
            <CruisesOverview cruisesSnapshot={cruisesSnapshot} preText="For " />
          </div>

          {chosenGroupToPay === "ExcursionAndDinning" && (
            <CartElementsConfirmationCombined
              paidExcursions={cartSnapshot.excursions}
              paidTacEntries={cartSnapshot.tac}
              cruiseStartDate={
                cruisesSnapshot && cruisesSnapshot.cruiseStartDate
              }
            />
          )}

          {chosenGroupToPay === "OSW" && (
            <CartElementsConfirmationCombined
              oswEntries={cartSnapshot.osw}
              cruiseStartDate={
                cruisesSnapshot && cruisesSnapshot.cruiseStartDate
              }
            />
          )}

          {chosenGroupToPay === "MusementExcursions" && (
            <CartElementsConfirmationCombined
              musementEntries={cartSnapshot.musement}
              cruiseStartDate={
                cruisesSnapshot && cruisesSnapshot.cruiseStartDate
              }
            />
          )}

          {chosenGroupToPay === "MxpDining" && (
            <CartElementsConfirmationCombined
              mxpEntries={cartSnapshot.mxp}
              cruiseStartDate={
                cruisesSnapshot && cruisesSnapshot.cruiseStartDate
              }
            />
          )}

          <div className="text-right mb-4">
            <Price
              preText="Total price paid today"
              price={getCartSumBySelectedCart().toString()}
            />
          </div>
        </div>
      </div>
      {/*What happens next*/}
      <div className="bg-blue-lightest text-center pt-8 pb-4">
        <div className="container mx-auto text-blue-dark">
          <div className="text-2xl uppercase px-4">What happens next?</div>

          <div className="flex flex-wrap justify-center">
            <Step title="Confirmation" imageName="at-symbol.svg">
              <div className="text-grey-darkest">
                <p>
                  You'll shortly receive a confirmation email at{" "}
                  {isB2B
                    ? locationState.confirmationMail
                    : payment.confirmationMail}
                </p>
              </div>
            </Step>

            <Step title="Manage excursions" imageName="tablet.svg">
              <div className="text-grey-darkest">
                <p>Log in to your account to manage your cruise excursions</p>
              </div>
            </Step>

            <Step title="Tickets" imageName="booking.svg">
              <div className="text-grey-darkest">
                <p>Your tickets will be delivered onboard</p>
              </div>
            </Step>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentConfirmationOverview;

type StepProps = PropsWithChildren & {
  imageName: string;
  title: string;
};
const Step: FC<StepProps> = ({ imageName, title, children }) => {
  return (
    <div className="w-full sm:w-1/2 md:w-1/4 flex flex-col items-center py-4 px-4">
      <div className="w-30 h-30 bg-white rounded-full flex items-center justify-center">
        <img className="h-16" alt="step logo" src={imagePath(imageName)} />
      </div>
      <div className="uppercase mt-4">{title}</div>
      <div className="mt-3 leading-normal">{children}</div>
    </div>
  );
};

import { getHubBaseUrl } from "config/configUtils";

export const imagePath = (fileName: string) => {
  return (
    require(`../../resources/images/${fileName}`)?.default ??
    require(`../../resources/images/${fileName}`)
  );
};

export const CRUISE_HEADER_IMAGE_PLACEHOLDER_ARRAY = [
  "images/CRUISE_HEADER_IMAGE_PLACEHOLDER_01.jpg",
  "images/CRUISE_HEADER_IMAGE_PLACEHOLDER_02.jpg",
  "images/CRUISE_HEADER_IMAGE_PLACEHOLDER_03.jpg",
  "images/CRUISE_HEADER_IMAGE_PLACEHOLDER_04.jpg",
];

export const getRandomImage = () => {
  const randomIndex = Math.floor(
    Math.random() * CRUISE_HEADER_IMAGE_PLACEHOLDER_ARRAY.length
  );
  return CRUISE_HEADER_IMAGE_PLACEHOLDER_ARRAY[randomIndex];
};

/**
 * Transforms a given URL to a new base domain.
 * @param originalUrl - The original URL string.
 * @returns The transformed URL string with the new base domain.
 */
export const transformMxpImageUrl = (
  originalUrl: string | null | undefined
): string => {
  if (
    typeof originalUrl !== "string" ||
    !originalUrl?.toLowerCase()?.includes("mxp")
  ) {
    return "";
  }

  const presalesBaseDomain = window?.location?.origin;

  if (!/(presales|marellacruisecontrol)/.test(presalesBaseDomain)) {
    return "";
  }

  const url = new URL(originalUrl);

  // Clean up the pathname by removing the first "/" if it starts with "//"
  const cleanPathname = url.pathname.startsWith("//")
    ? url.pathname.slice(1) // Remove the extra leading "/"
    : url.pathname;

  return `${presalesBaseDomain}${cleanPathname}${url.search}`;
};

export const getResourceImageUrl = (
  resourceId?: string | null,
  { width = 0, category = "" } = {}
) => {
  // check if "resourseId" is a string and a "base64" png image
  if (
    typeof resourceId === "string" &&
    resourceId.includes("data:image/png;base64")
  ) {
    return resourceId;
  }

  // check if img url is from new cloud hub and return the url directly
  if (typeof resourceId === "string" && resourceId.includes("/spa/")) {
    return resourceId;
  }

  // check if img url is from new cloud hub and return the url directly
  if (
    typeof resourceId === "string" &&
    resourceId?.toLowerCase()?.includes("mxp")
  ) {
    return transformMxpImageUrl(resourceId);
  }

  // otherwise - build "blob" image
  const IMAGES_URL = getHubBaseUrl();
  let imageUrl = `${IMAGES_URL}blobs/image?reference=${resourceId}`;

  if (width) {
    imageUrl += `&width=${width}`;
  }

  if (category) {
    imageUrl += `&category=${category}`;
  }

  return imageUrl;
};

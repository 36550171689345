import { ICartState } from "interfaces/ReduxState/ICart";
import { CartActions } from "../actions/cartActions";

const INITIAL_STATE: ICartState = {
  totalPrice: 0,
  nrExcursions: 0,
  chosenGroupToPay: "ExcursionAndDinning",
  excursions: null,
  notBookableEntries: { excursions: [], tac: [] },
  tac: {},
  osw: {},
  musement: {},
  mxp: {},
  handlingInProgress: false,
};

const cartReducers = (state = INITIAL_STATE, action: CartActions) => {
  switch (action.type) {
    case "SET_CART":
      return {
        ...state,
        totalPrice: action.cart.totalPrice,
        nrExcursions: action.cart.nrExcursions,
        excursions: action.cart.excursions,
        notBookableEntries: action.cart.notBookableEntries,
        tac: action.cart.tacEntries,
        osw: action.cart.oswEntries,
        musement: action.cart.musementEntries,
        mxp: action.cart.mxpEntries,
        handlingInProgress: action.cart.handlingInProgress,
      };

    case "SET_CHOSEN_GROUP_TO_PAY":
      return {
        ...state,
        chosenGroupToPay: action.chosenGroupToPay,
      };

    case "CLEAR_CART":
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default cartReducers;

import axios from "axios";
import { getDinningUrl } from "config/configUtils";
import useAppSelector from "hooks/useAppSelector";
import { useCallback, useState } from "react";

type Props = {
  amount: number;
  currency: string;
  tenantReference: string;
  emailAddress: string;
  mobileNumber: string;
};

export const usePostInitPaymentAgencyMxpDining = () => {
  const userSessionId = useAppSelector(
    (state) => state.authentication.sessionId
  );
  const agencyAuthInfo = useAppSelector((state) => state.agency);

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const postInitPaymentAgencyMxpDining = useCallback(
    async ({
      amount,
      currency,
      tenantReference,
      emailAddress,
      mobileNumber,
    }: Props) => {
      setLoading(true);
      let initialError = null;
      const URL = `${getDinningUrl()}/api/payments/agency/confirm`;

      const response = await axios
        .post(
          URL,
          {
            amount,
            currency,
            tenantReference,
            emailAddress,
            mobileNumber,
            sessionId: agencyAuthInfo.sessionId,
            sessionType: agencyAuthInfo.sessionType,
            sessionName: agencyAuthInfo.sessionName,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              SessionId: userSessionId,
            },
          }
        )
        .catch((error) => {
          setError(error?.response?.data?.message);
          initialError = error?.response?.data?.message;
        });

      setData(response?.data);
      setLoading(false);

      return { responseData: response?.data, error: initialError };
    },
    [
      agencyAuthInfo.sessionId,
      agencyAuthInfo.sessionName,
      agencyAuthInfo.sessionType,
      userSessionId,
    ]
  );

  return { postInitPaymentAgencyMxpDining, data, loading, error };
};
